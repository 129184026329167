import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getDatabase, ref, onChildChanged, set, runTransaction, update, onValue, onChildAdded, onChildRemoved, push, serverTimestamp, query, limitToLast   } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCFuOz4JlryHchU6m-oO3qAEdtpM6x6MyU",
    authDomain: "metazonia-2022.firebaseapp.com",
    projectId: "metazonia-2022",
    storageBucket: "metazonia-2022.appspot.com",
    messagingSenderId: "372134114",
    appId: "1:372134114:web:d77d1d50a4b53cfe814ebb",
    measurementId: "G-YQX51FWHXS"
};

const FirebaseData = {
    app: null,
    analytics: null,
    auth: null,
    database: null,    
    user: null,
    userName: "",
    userEmail: "",
    userCompany: "",
    userPhone: "",
    userRole: "",
};

export function InitializeFirebase(){
    // FirebaseData['app'] = initializeApp(firebaseConfig);
    // FirebaseData['analytics'] = getAnalytics(FirebaseData['app']);
    // FirebaseData['auth'] = getAuth();
    // FirebaseData['database'] = getDatabase(FirebaseData['app']);    

    // onAuthStateChanged(FirebaseData['auth'], (user) => {
    //     if (user) {  
    //         FirebaseData['user'] = user;           
    //         const userDataRef = ref(FirebaseData['database'], 'users/'+user.uid);
    //         onValue(userDataRef, (snapshot) => {        
    //             FirebaseData['userName'] = snapshot.val().name;
    //             FirebaseData['userEmail'] = snapshot.val().email;
    //             FirebaseData['userCompany'] = snapshot.val().company;
    //             FirebaseData['userPhone'] = snapshot.val().phone;
    //             FirebaseData['userRole'] = snapshot.val().role;  
    //         }, {
    //             onlyOnce: true
    //         });
    //     } else {          

    //     }
    // });

    // const ReloadPageRef = ref(FirebaseData['database'], 'config/reload');        
    // onChildChanged(ReloadPageRef, (data) => {        
    //     window.location.reload(true);
    // });    
}

export function RegisterCount(refClick){   
    // console.log(refClick);
    // if(FirebaseData['user'] != null){ 
    //     const countRef = ref(FirebaseData['database'], 'users/'+FirebaseData['user'].uid+'/click_'+refClick);
    //     runTransaction(countRef, (click) => { 
    //         click++;        
    //         return click;
    //     });
    // }
}

window.ExternalRegisterCount = (ref) => {
    // RegisterCount(ref);
}

export function RegisterNavigation(refClick){ 
    // console.log(refClick);
    // if(FirebaseData['user'] != null){ 
    //     const countRef = ref(FirebaseData['database'], 'users/'+FirebaseData['user'].uid+'/node_'+refClick);
    //     runTransaction(countRef, (click) => { 
    //         click++;        
    //         return click;
    //     });
    // }
}

window.ExternalRegisterNavigation = (ref) => {
    // RegisterNavigation(ref);
}

export function CreateUser(data, callbackSuccess, callbackError){
    callbackSuccess();
    // let timeCreate = new Date();

    // let dayCreate = timeCreate.getDate();
    // dayCreate = dayCreate.toString();
    // if(dayCreate.length == 1){
    //     dayCreate = "0" + dayCreate;
    // }
    
    // let monthCreate = timeCreate.getMonth() + 1;
    // monthCreate = monthCreate.toString();
    // if(monthCreate.length == 1){
    //     monthCreate = "0" + monthCreate;
    // }
    
    // let yearCreate = timeCreate.getFullYear();
    
    // let hourCreate = timeCreate.getHours();
    // hourCreate = hourCreate.toString();
    // if(hourCreate.length == 1){
    //     hourCreate = "0" + hourCreate;
    // }
    
    // let minutesCreate = timeCreate.getMinutes();
    // minutesCreate = minutesCreate.toString();
    // if(minutesCreate.length == 1){
    //     minutesCreate = "0" + minutesCreate;
    // }
    
    // let secondsCreate = timeCreate.getSeconds();
    // secondsCreate = secondsCreate.toString();
    // if(secondsCreate.length == 1){
    //     secondsCreate = "0" + secondsCreate;
    // }
    
    // let momentCreate = yearCreate + "-" + monthCreate + "-" + dayCreate + " " + hourCreate + ":" + minutesCreate + ":" + secondsCreate;
    
    // setPersistence(FirebaseData['auth'], browserSessionPersistence)
    // .then(() => {    
    //     createUserWithEmailAndPassword(FirebaseData['auth'], data.email, '123456')
    //     .then((userCredential) => {        
    //         FirebaseData['user'] = userCredential.user;
    //         // console.log(userCredential.user);

    //         set(ref(FirebaseData['database'], 'users/' + FirebaseData['user'].uid), {
    //             name: data.name,
    //             email: data.email,
    //             company: data.company,
    //             phone: data.phone,
    //             role: data.role,                
    //             accept: data.accept,
    //             criacao_cadastro: momentCreate,
    //             ultimo_acesso: momentCreate,
    //         })
    //         .then(() => {                
    //             callbackSuccess();
    //         })
    //         .catch((error) => {
    //             // console.error(error);
    //             callbackError("Error de conexão com o servidor");
    //         });
    //     })
    //     .catch((error) => {
    //         // console.error(error);
    //         callbackError("Usuário já cadastrado");            
    //     });

    // })
    // .catch((error) => {
        
    // });    
}


export function SignInUser(email, callbackSuccess, callbackError){
    callbackSuccess();
    // setPersistence(FirebaseData['auth'], browserSessionPersistence)
    // .then(() => {    
    //     signInWithEmailAndPassword(FirebaseData['auth'], email, '123456')
    //     .then((userCredential) => {        
    //         const user = userCredential.user;
    //         FirebaseData['user'] = user;
    //         let timeCreate = new Date();

    //         let dayCreate = timeCreate.getDate();
    //         dayCreate = dayCreate.toString();
    //         if(dayCreate.length == 1){
    //             dayCreate = "0" + dayCreate;
    //         }
            
    //         let monthCreate = timeCreate.getMonth() + 1;
    //         monthCreate = monthCreate.toString();
    //         if(monthCreate.length == 1){
    //             monthCreate = "0" + monthCreate;
    //         }
            
    //         let yearCreate = timeCreate.getFullYear();
            
    //         let hourCreate = timeCreate.getHours();
    //         hourCreate = hourCreate.toString();
    //         if(hourCreate.length == 1){
    //             hourCreate = "0" + hourCreate;
    //         }
            
    //         let minutesCreate = timeCreate.getMinutes();
    //         minutesCreate = minutesCreate.toString();
    //         if(minutesCreate.length == 1){
    //             minutesCreate = "0" + minutesCreate;
    //         }
            
    //         let secondsCreate = timeCreate.getSeconds();
    //         secondsCreate = secondsCreate.toString();
    //         if(secondsCreate.length == 1){
    //             secondsCreate = "0" + secondsCreate;
    //         }
            
    //         let momentCreate = yearCreate + "-" + monthCreate + "-" + dayCreate + " " + hourCreate + ":" + minutesCreate + ":" + secondsCreate;

    //         update(ref(FirebaseData['database'], 'users/' + FirebaseData['user'].uid), {
    //             ultimo_acesso: momentCreate
    //         })
    //         .then((data) => {      
    //             callbackSuccess();
    //         });
            
    //     })
    //     .catch((error) => {
    //         callbackError();
    //     });
    // })
    // .catch((error) => {
        
    // });

}