import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from "axios";

import JsonData from "Interfaces/JsonData.js";

import { InitializeFirebase } from "Services/Firebase.js"

const root = ReactDOM.createRoot(document.getElementById('root'));

Axios.get(`/json/contents.json`).then(({data}) => { 

  InitializeFirebase();

  for (const key in data) {
    JsonData[key] = data[key];
  }

  console.log(JsonData);

  document.getElementById("loading").style.display = "none";

  root.render(
    <React.Fragment>
      <App />
    </React.Fragment>
  );
});

